<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <Navbar />
        <v-row v-if="success" justify="center" class="ma-0 mt-5">
            {{ $t("deleteAccountCancel.message") }}
        </v-row>
        <v-row v-else justify="center" class="ma-0 mt-5">
            <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
        </v-row>
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'
import PopupDialog from '@/components/partials/PopupDialog'
import axios from 'axios'
    export default {
        name: "PasswordRecoveryCode",
        components: {Navbar, PopupDialog},
        data() {
            return {
                dialog: false,
                success: false,
                error: ''
            }
        },
        created () {
                axios.get('/auth/delete_account_cancel')
                .then(() => {
                    this.success = true
                    this.error = ''
                    this.$store.state.deleteDate = null
                })
                .catch(() => {
                    this.error = this.$t("deleteAccountCancel.errors.any")
                })
            }
        }
</script>

<style lang="scss" scoped>

</style>